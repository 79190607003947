<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row"
							v-if="$whois.admin()"
						>
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("uom.shopName")
                    }}</label>
                    <a-select
                        v-model="model.shop_id"
                        :options="shopList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('uom.shopName')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{
                        $t("uom.nameKh")
                    }}</label>
                    <input
                        v-model="model.uom_name_kh"
                        type="text"
                        class="form-control"
                        :placeholder="$t('uom.nameKh')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('uom_name_kh')"
                    >
                        {{ errors.first("uom_name_kh") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("uom.nameEn")
                    }}</label>
                    <input
                        v-model="model.uom_name_en"
                        type="text"
                        class="form-control"
                        :placeholder="$t('uom.nameEn')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('uom_name_en')"
                    >
                        {{ errors.first("uom_name_en") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSaveAddNew"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";

export default {
    name: "uomForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
								uom_id: null,
                shop_id: undefined,
                uom_name_kh: "",
                uom_name_en: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/uom", ["formModels", "edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        }
    },
    created() {
        this.fetchFormView({ params: {}, index: undefined });
    },
    methods: {
        ...mapActions("inventory/uom", [
            "getFormViewData",
            "store",
            "update"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(() => {
                    this.setEditData();
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.update({
							id: this.model.uom_id,
							data: this.model
						})
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.shop_id = undefined;
            this.model.uom_name_kh = '';
            this.model.uom_name_en = "";
        },
				setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.uom_id = this.edit_data.uom_id;
                this.model.shop_id = this.edit_data.shop_id;
                this.model.uom_name_kh = this.edit_data.uom_name_kh;
                this.model.uom_name_en = this.edit_data.uom_name_en;
            }
        }
    }
};
</script>
